import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-roulette-player-to-steal',
  templateUrl: './roulette-player-to-steal.component.html',
  styleUrls: ['./roulette-player-to-steal.component.scss'],
})
export class PlayerToStealComponent {
  @Input() playerToSteal: any;
  @Input() isWinner: boolean;
  @Input() isSpinning: boolean = false;
  @Output() spinComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  rotationAngle: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isSpinning'] && this.isSpinning) {
      this.rouletteSpin();
    }
  }
  
  rouletteSpin() {
    // Lógica para girar la ruleta
    const spins = Math.floor(Math.random() * 4) + 2;
    const additionalAngle = Math.floor(Math.random() * 360);
    this.rotationAngle = spins * 360 + additionalAngle;

    // Simular el tiempo de giro
    setTimeout(() => {
      this.spinComplete.emit(true);
    }, 5000);
  }
}