<div class="container">
  <h1 *ngIf="isWinner">¡GANASTE UN NUEVO JUGADOR!</h1>
  <h1 *ngIf="!isWinner">¡QUÉ LÁSTIMA!</h1>
  <div class="content-container">
    <p *ngIf="isWinner">
      Ahora puedes sumarlo a tu equipo titular
    </p>
    <p *ngIf="!isWinner">
      Ya no lo tienes en tu plantel para sumarlo a tu equipo
    </p>
    <div class="card-container">
      <app-medium-card [card]="playerToSteal.players" class="first-card animate-first-card"></app-medium-card>
    </div>
  </div>
</div>