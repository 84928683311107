import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';
import { ISponsorTournament } from '../interfaces/iTournamentSponsor';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {
  URL_API = this.globalService.URL_API;

  public tournaments: ISponsorTournament[] = [
    { 
      image: "/assets/images/cups/tournament_logo_nike.svg", 
      url: "/match/season",
      color: "#0F0C0C",
      fontColor: "#F5F5FA",
      title: "Camino",
      subtitle: "a la gloria",
      description: "compite contra otros jugadores"
    },
    { 
      image: "/assets/images/cups/tournament_logo_caliente.svg", 
      url: "/match/friendly-tournament",
      color: "#F5F5FA",
      fontColor: "#0F0C0C",
      title: "Arena",
      subtitle: "de amigos",
      description: "Crea torneo con tus amigos"
    },
    { 
      image: "/assets/images/cups/tournament_logo_coca-cola.svg", 
      url: "/match/friendly",
      color: "#E61D2B",
      fontColor: "#F5F5FA",
      title: "Arena",
      subtitle: "amistoso",
      description: "Desafía a otros jugadores en partidos unicos",
      backgroundImage: "/assets/images/cups/Coca-Cola_glass_bottle.svg"
    },
    { 
      image: "/assets/images/cups/tournament_logo_bet365.svg", 
      url: "/match/battle/find-match",
      color: "#027B5B",
      fontColor: "#F5F5FA",
      title: "Batalla",
      subtitle: "por el botín",
      description: "Juega por la posibilidad de robar un jugador al ganar",
      backgroundImage: "/assets/images/cups/Coca-Cola_glass_bottle.svg"
    },
  ];

  public tournamentSponsor: WritableSignal<any> =  signal({});

  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
  ) { }

  getSponsors(type: string): Observable<any> {
    return this.http.get(`${this.URL_API}/sponsor`, { params: {type} });
  }

  getSponsorByTeamId(teamId: number): Observable<any> {
    return this.http.get(`${this.URL_API}/team-sponsor/team/${teamId}`);
  }

  selectSponsor(teamSponsorBody): Observable<any> {
    return this.http.post(`${this.URL_API}/team-sponsor`, teamSponsorBody);
  }
}
