import { Component, Input, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { ICard } from 'src/app/interfaces/ICard';
import { IUser } from 'src/app/interfaces/IUser';
import { FormationsService } from 'src/app/services/formations.service';
import { PlayerListService } from 'src/app/services/player-list.service';
import { TeamService } from 'src/app/services/team.service';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';
import { BuyContractPlayerModalComponent } from '../modals/buy-contract-player-modal/buy-contract-player-modal.component';
import { Shorthand } from 'src/app/interfaces/highlight-skill.types';
import { PlayerChemistry } from 'src/app/enums/playerChemistry';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-player-picker',
  templateUrl: './player-picker.component.html',
  styleUrls: ['./player-picker.component.scss'],
})
export class PlayerPickerComponent {
  @ViewChild(IonContent) content: IonContent;

  @Input() card: any;
  @Input() teamInfo: any

  reservePlayers: ICard[];
  activePlayers: ICard[];
  selectedPlayer: ICard;
  substitutePlayer: ICard;
  suggestedSubstitutions: ICard[];
  user: IUser;
  activePlayerIds;
  hightlightSkill: Shorthand = Shorthand.FIS;
  chemistry: string;
  tooltipMessage: string;
  fullTeam: any;

  constructor(
    private utilsService: UtilsService,
    private playerListService: PlayerListService,
    private teamService: TeamService,
    private formationsService: FormationsService,
    private userService: UsersService
  ) { }

  async ionViewWillEnter() {
    console.log(this.card, this.teamInfo)
    this.utilsService.showLoading();
    this.user = this.userService.getLoggedUser();
    if(!this.card.player){ // esto es para comprobar que no es un cambio, es una posición vacia
      let formationposition_id = this.card.formationposition_id
      let currentPosition = AppConstants.positions.find(i => i.id === this.card?.actualPosition);
      this.card = {
        ...currentPosition,
        formationposition_id
      };
    } 
    this.selectedPlayer = this.card;
    this.chemistry = this.teamService.getChemistryPlayer(this.selectedPlayer?.player?.full_position, this.selectedPlayer?.lineUpPosition);
    if(!this.teamInfo){
      const [_error, response] = await to(lastValueFrom( this.teamService.getTeamInformation(this.user.teams[0].id)));
      if(response) this.fullTeam = response.data;
    } else {
      this.fullTeam = this.teamInfo
    }
    await this.setTooltipMessage(this.chemistry);
    await this.getActivePlayers();
    await this.getReservePlayers();
    this.utilsService.dismissLoading();
  }

  async getActivePlayers() {
    this.activePlayers = this.fullTeam.formations[0].lineUp
    this.activePlayerIds = new Set(
      this.activePlayers.map((player) => player.player.id)
    );
  }

  async getReservePlayers() {
    this.teamService
      .getTeamCards(this.user.teams[0].id, true)
      .subscribe((res: any) => {
        this.reservePlayers = res?.data?.cards?.filter((player) =>
          !this.activePlayerIds.has(player?.player?.id) &&
          player?.player?.full_position?.type !== this.selectedPlayer?.lineUpPosition?.type
        ) || [];
        this.suggestedSubstitutions = res?.data?.cards?.filter((player) =>
          !this.activePlayerIds.has(player?.player?.id) && 
          player?.player?.full_position?.type === 
          (!this.selectedPlayer?.lineUpPosition ? this.selectedPlayer?.type : this.selectedPlayer?.lineUpPosition?.type) // comprobamos si existe o no un jugador en la posición
        ) || [];
      });
  }

  async renew() {
    const modal = await this.utilsService.showModalSheet(
      BuyContractPlayerModalComponent,
      { card: this.card },
      0.6
    );
    const metaModal = modal.onDidDismiss();
    const result = (await metaModal).data;
    if (result) {
      this.utilsService.dismissModal(result);
    }
  }

  async pickPlayer(card: any) {
    const isSamePlayer = this.selectedPlayer?.player === card?.player? true: false;
    if (!isSamePlayer && this.card.player) {
      this.substitutePlayer = card;
    }
    if(!this.card.player){
      this.selectedPlayer = card
      this.selectedPlayer.formationposition_id = this.card.formationposition_id     //asignamos la formationposition_id al nuevo jugador seleccionado
      this.chemistry = this.teamService.getChemistryPlayer(
        this.selectedPlayer?.player?.full_position, 
        this.selectedPlayer?.lineUpPosition ? this.selectedPlayer?.lineUpPosition : this.card, // revisamos si el jugador trae la lineUpPosition, si no está en los datos del principio (tarjeta vacia de jugador)
        );
    } else {
      this.getChemistry()
    }
  }

  async changePlayer(card: any) {  
    this.utilsService.showLoading();
    this.content.scrollToTop(1500);

    const isActivePlayer = this.activePlayers.find(
      (p) => p?.player?.id === card?.player?.id
    );

    const isSelectedPlayerInField = this.activePlayers.find(
      (p) => p.player?.id === this.selectedPlayer?.player?.id
    );

    const teamFormationId = this.fullTeam.formations[0].id;

    if (!isActivePlayer) {
      // Change principal atributes from the card
      if (!card?.player?.id) {
        card = this.selectedPlayer;
      } else {
        this.selectedPlayer = card;
      }
      this.card.id = card?.id;
      this.card.player = card?.player;
      this.card.contract = card?.nrocontrato;

      await to(
        lastValueFrom(
          this.formationsService.susbstitutePlayerInFormation(
            teamFormationId,
            this.card.formationposition_id,
            card?.code ? card?.code : card?.id
          )
        )
      );
    } else if (card?.code) {
      // Swich player because bouth are in the field
      const oldPlayer = card;
      const selectedPlayer = this.card;

      await to(
        lastValueFrom(
          this.formationsService.susbstitutePlayersInFormation(
            teamFormationId,
            oldPlayer.formationposition_id,
            selectedPlayer.formationposition_id,
            selectedPlayer?.code,
            oldPlayer?.code
          )
        )
      );

    } else if (!card?.code) {
      // si el jugador es de distinta categoria se puede reemplazar.
      if (card?.player?.category?.id !== isActivePlayer.player?.category.id) {
        await to(
          lastValueFrom(
            this.formationsService.susbstitutePlayerInFormation(
              teamFormationId,
              this.card.formationposition_id,
              card?.code
            )
          )
        );
      }
    }
    this.utilsService.dismissLoading();
    this.utilsService.dismissModal();
  }
  
  close() {
    this.utilsService.dismissModal();
  }


  async getChemistry() {
    this.chemistry =
      await this.teamService.getChemistryPlayer(this.substitutePlayer?.player.full_position, this.selectedPlayer.lineUpPosition);
    await this.setTooltipMessage(this.chemistry);
  }

  async setTooltipMessage(playerChemistry: string) {
    if (playerChemistry === PlayerChemistry.SUCCESS) {
      this.tooltipMessage = 'Este jugador está muy a gusto en esta posición';
    } else if (playerChemistry === PlayerChemistry.WARNING) {
      this.tooltipMessage = 'Esta posición es indiferente para este jugador';
    } else if (playerChemistry === PlayerChemistry.DANGER) {
      this.tooltipMessage = 'Este jugador no está a gusto en esta posición';
    }
  }

  redirectToMarket() {
    this.close();
    this.utilsService.goPage('/market');
  }

  isPlayerRenewable(playerId: number): boolean {
    const card = this.teamInfo?.cards?.find(card => card.player_id === playerId);
    return card?.renewable === false;
  }
}
