import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class RouletteService {
  urlApi: string;
  constructor(
    private Http: HttpClient,
    public GlobalService: GlobalService,
  ) {
    this.urlApi = this.GlobalService.URL_API;
  }

  getRouletteReward(): Observable<any>{
    return this.Http.get<any>(`${this.urlApi}/roulette/get-reward`);
  }

  getPlayerToSteal(teamId: number): Observable<any>{
    return this.Http.get<any>(`${this.urlApi}/roulette/steal-player/team/${teamId}`);
  }

  getPackReward(): Observable<any>{
    return this.Http.get<any>(`${this.urlApi}/roulette/pack-reward`);
  }

  stealAPlayer(body: any) {
    return this.Http.post<any>(`${this.urlApi}/roulette/steal-player`, body);
  }

  coinsReward(body: any) {
    return this.Http.post<any>(`${this.urlApi}/roulette/coins-reward`, body);
  }

  savePackReward(teamId: any) {
    return this.Http.get<any>(`${this.urlApi}/roulette/pack-reward/team/${teamId}`);
  }

  getTeamLineUp(teamId: number): Observable<any>{
    return this.Http.get<any>(`${this.urlApi}/roulette/steal-player/team/${teamId}/line-up`);
  }
}
