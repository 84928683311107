import { Component, Input, OnInit } from "@angular/core";
import { ICard } from "../../interfaces/ICard";
import { ModalController } from "@ionic/angular";
import { PlayerListService } from "../../services/player-list.service";
import { TeamService } from "../../services/team.service";
import { UsersService } from "../../services/users.service";
import { UtilsService } from "../../services/utils.service";
import to from "await-to-js";
import { IApiGenericResponse } from "../../interfaces/IApiResponse";
import {
  IFormationInfo,
  IFormationInfoData,
} from "../../interfaces/IFormationInfo";
import { IUser } from "../../interfaces/IUser";
import { SellAttentionModalComponent } from "../modals/sell-attention-modal/sell-attention-modal.component";
import { ProductsService } from "../../services/products.service";

@Component({
  selector: "app-quick-sell",
  templateUrl: "./quick-sell.component.html",
  styleUrls: ["./quick-sell.component.scss"],
})
export class QuickSellComponent implements OnInit {
  @Input() allPlayers: ICard[] = [];
  @Input() excess: number;

  modeView: boolean = true;
  user: IUser;
  currentLineupIds: number[] = [];

  playerEnableToRemoveList: ICard[] = [];
  playerEnableToRemoveListFiltered: ICard[] = [];

  playerReserveList: ICard[] = [];
  playerReserveListFiltered: ICard[] = [];

  playerWOContractList: ICard[] = [];
  playerWOContractListFiltered: ICard[] = [];

  playerDeleted: ICard[] = [];
  remainingPlayers: ICard[] = [];
  deleteBtn: boolean = true;
  product: any;

  constructor(
    private playerListService: PlayerListService,
    private teamService: TeamService,
    private usersService: UsersService,
    private utilsService: UtilsService,
    private modalController: ModalController,
    private productServices: ProductsService,
  ) {}

  async ionViewDidEnter() {
    this.utilsService.showLoading();
    this.user = this.usersService.getLoggedUser();
    this.product = this.productServices.loadedProduct;
    // await this.loadActivePlayers();
    await this.loadOtherPlayers();
    this.utilsService.dismissLoading();
  }

  ngOnInit() {
    console.log("ALL", this.allPlayers, "EXCESS", this.excess);
  }

  viewChanged(value: boolean) {
    this.modeView = value;
  }

  /**
   * Esta función sirve para buscar jugadores especificos en el listado, previamente filtrados.
   * @param searchTerm buscador de jugadores. Type: String
   * @returns
   */
  filterByCriteria(searchTerm: any) {
    const val = searchTerm.target.value;
    if (val.trim() === "") {
      this.playerEnableToRemoveListFiltered = this.playerEnableToRemoveList;
      this.playerReserveListFiltered = this.playerReserveList;
      return;
    }
    this.playerEnableToRemoveListFiltered = this.playerListService.filterItems(
      this.playerEnableToRemoveList,
      val,
    );
    this.playerReserveListFiltered = this.playerListService.filterItems(
      this.playerReserveList,
      val,
    );
  }

  /**
   * Esta función es para traer todos los jugadores tanto sea los que estan en reserva
   * como los jugadores que no poseen contrato
   */
  async loadOtherPlayers() {
    this.utilsService.showLoading();
    if (!!this.allPlayers) {
      this.playerReserveList = this.playerListService.getReservePlayers(
        this.allPlayers,
        this.currentLineupIds,
      );

      this.playerReserveListFiltered = this.playerReserveList.filter(
        (i) => !this.currentLineupIds.includes(i.id),
      );

      this.playerWOContractList =
        this.playerListService.getPlayersWithoutContract(this.allPlayers);
      this.playerWOContractListFiltered = this.playerWOContractList;
      this.utilsService.dismissLoading();
    } else {
      this.utilsService.dismissLoading();
      this.utilsService.showToast(
        "Ooops algo salió mal, intentalo de nuevo",
        "danger",
        2000,
        "top",
      );
    }
  }
  /**
   * Esta función es para filtrar a los jugadores que están en la cancha.
   * es decir, filtra para mostrar solo a los jugadores que estan en la banca
   * y no traer a la totalidad de jugadores
   */
  async loadActivePlayers() {
    this.utilsService.showLoading();
    const [error, response] = await to<IApiGenericResponse<IFormationInfo>>(
      this.teamService.getTeamInformation(this.user.teams[0].id).toPromise(),
    );
    if (response) {
      this.utilsService.dismissLoading();
      const responseData: IFormationInfo = response.data;

      const currentLineup: IFormationInfoData = responseData.formations.find(
        (i) => i.formation_id === responseData.current_formations.id,
      );
      this.currentLineupIds = currentLineup.lineUp.map((i) => i.code);

      const activePlayers: ICard[] = this.allPlayers.filter(
        (i) => !this.currentLineupIds.includes(i.id),
      );

      this.playerEnableToRemoveList = activePlayers;
      this.playerEnableToRemoveListFiltered = activePlayers;
      this.utilsService.dismissLoading();
    }
  }

  checkSell(player: ICard) {
    const index = this.playerDeleted.indexOf(player);
    if (index > -1) {
      this.playerDeleted.splice(index, 1);
    } else {
      this.playerDeleted.push(player);
    }
  }

  async sell() {
    if (this.playerDeleted.length < this.excess) {
      await this.utilsService.showToast(
        "Todavía debes desvincular " +
          (this.excess - this.playerDeleted.length) +
          " jugadores",
      );
    } else {
      const modal = await this.modalController.create({
        component: SellAttentionModalComponent,
        breakpoints: [0, 0.8, 1],
        initialBreakpoint: 0.5,
        componentProps: { totalToSell: this.playerDeleted.length },
      });
      await modal.present();
      modal.onDidDismiss().then(async (res) => {
        console.log(res);
        if (!!res.data) {
          this.utilsService.showLoading();
          this.usersService.sellExcess(this.playerDeleted).subscribe((res) => {
            this.remainingPlayers = this.allPlayers.filter(
              (el: ICard) => !this.playerDeleted.includes(el),
            );
            this.deleteBtn = false;
            this.utilsService.dismissLoading();
          });
        }
      });
    }
  }

  close() {
    this.modalController.dismiss();
  }
}
