<ion-row class="ion-justify-content-center ion-align-items-center">
  <ion-col size="12" class="ion-text-center">
    <h1 *ngIf="win && !tie">GANASTE</h1>
    <h1 *ngIf="!win && !tie">PERDISTE</h1>
    <h1 *ngIf="tie">EMPATASTE</h1>
    <p *ngIf="!tie">1 tiro de ruleta</p>
    <p *ngIf="tie">Ningún equipo obtiene premios</p>
    <div class="ion-text-start">
      <ion-button (click)="mute()" color="light" fill="outline">
        <ion-icon [name]="isMute ? 'volume-mute-outline' : 'volume-high-outline'"></ion-icon>
      </ion-button>
    </div>
    <ion-button (click)="redirectToRoulette()" color="primary" class="ion-margin-top block">
      Continuar
    </ion-button>
  </ion-col>
  <canvas *ngIf="tie || win" id="canvas"></canvas>
</ion-row>