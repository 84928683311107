import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { IRangeValue } from 'src/app/interfaces/IRangeValue';
import { RouletteService } from 'src/app/services/roulette.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-roulette-pack-reward',
  templateUrl: './roulette-pack-reward.component.html',
  styleUrls: ['./roulette-pack-reward.component.scss'],
})
export class RoulettePackRewardComponent implements OnChanges {
  @Input() isSpinning: boolean = false;
  @Input() packReward: any = {};
  @Input() packRewardDetail: any = {};
  @Input() isWinner:boolean = false;
  @Input() winnerId: number;
  @Output() spinComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  rotationAngle: number = 0;
  isOpen: boolean = false;
  newCards: any[];
  firstCard: any;
  elementRotateDeg = 0;
  elementsOpacity = 0;
  elementsOpacityInverted = 1;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isSpinning'] && this.isSpinning) {
      this.rouletteSpin();
    }
  }

  constructor(
    private rouletteService: RouletteService,
    private utilsServices: UtilsService) {}
  
  rouletteSpin() {
    // Lógica para girar la ruleta
    const spins = Math.floor(Math.random() * 4) + 2;
    const additionalAngle = Math.floor(Math.random() * 360);
    this.rotationAngle = spins * 360 + additionalAngle;

    // Simular el tiempo de giro
    setTimeout(() => {
      this.spinComplete.emit(true);
    }, 5000);
  }

  async openPack() {
    this.utilsServices.showLoading();
    const [_error, response] = await to(lastValueFrom(this.rouletteService.savePackReward(this.winnerId)));
    if(response) {
      this.newCards = response?.data?.packs_details;
      if (this.newCards.length > 0) {
        this.firstCard = this.newCards[0]?.players;
      }
    }

    setTimeout(() => {
      this.isOpen = true;
    }, 3000);
    this.utilsServices.dismissLoading();
  }

  changeOpacity(event: IRangeValue) {
    this.elementsOpacity = event.value / 10;
    this.elementsOpacityInverted = event.invertedValue / 10;
    this.elementRotateDeg = event.value;
  }
}