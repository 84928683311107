<div class="container">
  <div>
    <img src="assets/icon/icon-coins.svg" alt="icon-coins.svg">
    <h1 *ngIf="isWinner">¡BRAVO!</h1>
    <h1 *ngIf="!isWinner">¡COINS!</h1>
    <div>
      <p *ngIf="isWinner">
        Ganaste 5000 coins
      </p>
      <p *ngIf="!isWinner">
        Tu rival ganó 5000 coins
      </p>
    </div>
  </div>
</div>