<div class="ruleta-container">
  <h1 *ngIf="isWinner">¡GIRA LA RULETA PARA ROBAR UN JUGADOR!</h1>
  <h1 *ngIf="!isWinner">¡GIRARÁN LA RULETA PARA ROBARTE UN JUGADOR!</h1>
  <div class="ruleta">
    <ul class="roulette-list">
      <li class="roulette-item" *ngFor="let player of looserLineUp">{{ player.lastname }}</li>
    </ul>
    <div class="roulette-marker"></div>
  </div>
</div>
