import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-roulette-coins-reward',
  templateUrl: './roulette-coins-reward.component.html',
  styleUrls: ['./roulette-coins-reward.component.scss'],
})
export class RouletteCoinsRewardComponent implements OnChanges {
  @Input() isSpinning: boolean = false;
  @Input() isWinner: boolean = false;
  @Output() spinComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  rotationAngle: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isSpinning'] && this.isSpinning) {
      this.rouletteSpin();
    }
  }
  
  rouletteSpin() {
    // Lógica para girar la ruleta
    const spins = Math.floor(Math.random() * 4) + 2;
    const additionalAngle = Math.floor(Math.random() * 360);
    this.rotationAngle = spins * 360 + additionalAngle;

    // Simular el tiempo de giro
    setTimeout(() => {
      this.spinComplete.emit(true);
    }, 5000);
  }
}