import { Component, Input, OnInit } from '@angular/core';
import { IApiGenericResponse } from 'src/app/interfaces/IApiResponse';
import { IFormationInfo } from 'src/app/interfaces/IFormationInfo';
import { ISeason } from 'src/app/interfaces/ISeason';
import { IUser } from 'src/app/interfaces/IUser';
import { FormationsService } from 'src/app/services/formations.service';
import { TeamService } from 'src/app/services/team.service';
import { UsersService } from 'src/app/services/users.service';
import to from 'await-to-js';
import { MatchService } from 'src/app/services/match.service';
import { lastValueFrom } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalController } from '@ionic/angular';
import { TrophiesService } from 'src/app/services/trophies.service';

@Component({
  selector: 'app-seasson-end',
  templateUrl: './seasson-end.component.html',
  styleUrls: ['./seasson-end.component.scss'],
})
export class SeassonEndComponent implements OnInit {
  @Input() season: ISeason;
  @Input() matches: any[] = [];
  @Input() seasonResponse: string;
  @Input() seasonStats: any;

  // TODO: Reemplace activePlayers with real info
  activePlayers: any[];
  user: IUser;
  stage: string = 'ZONA NO DEFINIDA';
  teamStreak
  prize
  winACup: boolean = false

  constructor(
    private teamService: TeamService,
    public formationsService: FormationsService,
    private usersService: UsersService,
    private matchService: MatchService,
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
    private trophiesService: TrophiesService
  ) {}

  async ngOnInit() {
    this.user = this.usersService.getLoggedUser();
    console.log(this.season)

    this.matchService.getStreakOfTheLastGamesByTeamId(this.user?.teams[0]?.id, 11, 1).subscribe((res)=> {
      console.log('TEAM', res)
      this.teamStreak = res.data;
      this.completeTeamStreak();
    });

    await this.loadActivePlayers();
    let points = this.season?.tournamentTeams?.points
    switch(true) {
      case points >= this.season.tournamentTeams.tournament.points_to_asc && points < this.season.tournamentTeams.tournament.points_to_champ:
        this.stage = '¡Subiste de categoría!';
        this.prize = this.season.tournamentTeams.tournament.coins_for_asc;
        break;
      case points >= this.season.tournamentTeams.tournament.points_to_champ:
        this.stage = '¡Saliste campeón!';
        this.prize = this.season.tournamentTeams.tournament.coins_for_champ;
        this.winACup = true;
        break;
      case points >= this.season.tournamentTeams.tournament.points_to_stay && points < this.season.tournamentTeams.tournament.points_honorary_zone:
        this.stage = 'Te mantuviste en tu categoría';
        this.prize = this.season.tournamentTeams.tournament.coins_for_stay;
        break;
      case points <= this.season.tournamentTeams.tournament.points_to_desc:
        this.stage = 'Descendiste de categoría. ¡Evoluciona tu equipo para avanzar de zona!';
        this.prize = this.season.tournamentTeams.tournament.coins_for_desc;
        break;
      case points >= this.season.tournamentTeams.tournament.points_honorary_zone && points < this.season.tournamentTeams.tournament.coins_for_asc:
        this.stage = '¡Saliste campeón! Y quedaste entre los 10 mejores de tu liga';
        this.prize = this.season.tournamentTeams.tournament.coins_honorary;
        this.winACup = true;
        break;
      default:
        this.stage = 'Te mantuviste en tu categoría';
        this.prize = this.season.tournamentTeams.tournament.coins_for_stay;
      break;
    }
  }

  completeTeamStreak() {
    if (this.teamStreak.length < 11) {
      const objectsToAdd = 11 - this.teamStreak.length;
      for (let i = 0; i < objectsToAdd; i++) {
        this.teamStreak.push({ 
          matchId: null,
          result: 'pending',
          date: null,
          localTeamId: null,
          localGoals: null,
          awayTeamId: null,
          awayGoals: null,
        });
      }
    }
  }

  getImagePath(result: string): string {
    switch (result) {
      case 'win':
        return 'assets/icon/match-win-season.svg';
      case 'tie':
        return 'assets/icon/match-tie-season.svg';
      case 'lost':
        return 'assets/icon/match-lose-season.svg';
      default:
        return 'assets/icon/match-pending-season.svg';
    }
  }

  async loadActivePlayers() {
    const [error, response] = await to<IApiGenericResponse<IFormationInfo>>(
      lastValueFrom(this.teamService.getTeamInformation(this.user.teams[0].id))
    );
    if (response) {
      const sortedTeam = response.data.formations[0].lineUp.sort((a, b) => b.player?.technical_skill_performance_index - a.player?.technical_skill_performance_index);
      this.activePlayers = sortedTeam.slice(0, 3);
      // this.activePlayers = response.data.formations[0].lineUp;
    }
  }

  async claimPrize(){
    this.utilsService.showLoading();
    if(!this.winACup){
      const [err, res]:[any,any] = await to(lastValueFrom(this.trophiesService.getCups(this.user.teams[0].id)))
      console.log(res)
      if(res){
        const response: any = Object.values(res)
        const [_, cupAssigned] = await to(lastValueFrom(this.trophiesService.setCup({teamId: this.user.teams[0].id, cupPrizeId: response[0].id})))
        if(cupAssigned){
          this.utilsService.showToast('Ganaste un trofeo! ve a verlo a tu perfil')
        }
      }
    }
    
    const [error, response] = await to<any>(
      lastValueFrom(this.usersService.payReward({id: this.user.id, coins: this.prize}))
    );
    if(response){
      this.modalCtrl.dismiss();
      this.utilsService.dismissLoading();
    }
  }
}
