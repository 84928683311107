<div class="ruleta-container">
  <h1 *ngIf="isWinner">¡GIRA LA RULETA PARA GANAR!</h1>
  <h1 *ngIf="!isWinner">{{ winnerTeamName.toUpperCase() }} GIRARÁ LA RULETA PARA OBTENER SU PREMIO</h1>
  <div class="ruleta" [ngStyle]="{'transform': 'rotate(' + rotationAngle + 'deg)'}">
    <div class="segment segment-1">
      <span>SOBRE CON JUGADORES</span>
    </div>
    <div class="segment segment-2">
      <span>ROBAS UN JUGADOR</span>
    </div>
    <div class="segment segment-3">
      <span>5.000 COINS</span>
    </div>
    <div class="segment segment-4">
      <span>NO RECIBES PREMIO</span>
    </div>
  </div>
</div>