export const environment = {
  production: true,
  apiBase: 'https://5pqmj6g3b1.execute-api.us-east-1.amazonaws.com/dev',
  apiGraphql: 'https://j66xat6htbbljoi7nojocuyifu.appsync-api.us-east-1.amazonaws.com/graphql',
  appsyncApikey: 'da2-oyzxeczsv5chfplaxmvdrrguju',
  publicKeyMercadopago: 'TEST-b49fd008-941b-494e-a423-afbd4f1bf8ef',
  appBase: 'http://stagging.ksport.com.s3-website-us-east-1.amazonaws.com/#',
  authGoogleUrl: 'https://ksport-dev-domain.auth.us-east-1.amazoncognito.com',
  authGoogleClientId: '1bs0trphli910ebgiii1a1hq6a',
  authGoogleScope: 'aws.cognito.signin.user.admin+email+openid+phone',
  googleRedirectUri: 'https://d14zwq6cpwwf1e.cloudfront.net/sign-in-google',
};
