<div class="ruleta-container">
  <h1 *ngIf="isWinner">¡BRAVO!</h1>
  <h1 *ngIf="!isWinner">¡PACK REWARD!</h1>
  <div>
    <p *ngIf="isWinner">
      Ganaste un sobre con jugadores
    </p>
    <p *ngIf="!isWinner">
      Tu rival ganó un sobre con jugadores
    </p>
  </div>

    <ion-grid class="product ion-margin-top">
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="12" class="product-content">
          <ng-container [ngTemplateOutlet]="isOpen ? openProductTemplate: productTemplate"></ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ng-template #productTemplate>
      <div class="body">
        <div class="image-container">
          <div class="slider-content" *ngIf="isWinner">
            <app-confirm-slide class="slider" themeStyle="black" [icon]="'/assets/images/scissors.svg'"
              [label]="'Desliza para recibir tu premio'" (actualValue)="changeOpacity($event)"
              (completed)="openPack()"></app-confirm-slide>
          </div>
          <img class="image-container-top" src="/assets/images/pack_part_top.svg" alt="pack_part_top.svg"
            [ngStyle]="{'transform': 'rotateZ('+(354+elementRotateDeg)+'deg)', '-webkit-transform': 'rotateZ('+(354+elementRotateDeg)+'deg)', '-ms-transform': 'rotateZ('+(354+elementRotateDeg)+'deg)'}" />
          <img class="image-container-bottom ion-margin-bottom" src="/assets/images/pack_part_bottom.svg"
            alt="pack_part_bottom.svg" />
          <img class="image-container-back" [ngStyle]="{'opacity': elementsOpacity}"
            [ngClass]="{'animated': elementsOpacity === 1}" src="/assets/images/card_part_back.svg"
            alt="card_part_back.svg" />
        </div>
        <div [ngStyle]="{'opacity': elementsOpacityInverted}" class="product-description">
          <ion-label>{{ packRewardDetail?.name }}</ion-label>
          <p *ngIf="packRewardDetail?.description">{{ packRewardDetail?.description }}</p>
        </div>
      </div>
    </ng-template>
    
    <ng-template #openProductTemplate>
      <div class="card ion-margin-top stacked--down-right">
        <div class="content">
          <img class="product-container-back" src="/assets/images/card_part_back.svg" alt="card_part_back.svg" />
          <app-medium-card [card]="this.firstCard" class="first-card animate-first-card"></app-medium-card>
        </div>
      </div>
    </ng-template>

</div>
