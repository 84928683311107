import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import ConfettiGenerator from 'confetti-js';
import { DefaultCoinPrizes } from 'src/app/enums/defaultCoinPrizes';
import { SocialSharingModalComponent } from "src/app/components/modals/social-sharing-modal/social-sharing-modal.component";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-win-lose-match-battle',
  templateUrl: './win-lose-match-battle.component.html',
  styleUrls: ['./win-lose-match-battle.component.scss'],
})
export class WinLoseMatchBattleComponent implements AfterViewInit {
  /**
   * This component receive result of match and show if you are winner or loser
   */

  @Input() win = false;
  @Input() tie = false;
  @Input() isTournament = false;
  @Input() isMute = false;
  @Input() typeOfTournament;
  @Input() tounamentId;
  @Input() winnerId: number;
  @Input() looserId: number;
  @Input() matchId: number;

  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Output() muteSound: EventEmitter<any> = new EventEmitter();
  @Output() redirect: EventEmitter<any> = new EventEmitter();

  WON_MATCH = DefaultCoinPrizes.WON_MATCH;
  LOSE_MATCH = DefaultCoinPrizes.LOSE_MATCH;
  TIE_MATCH = DefaultCoinPrizes.TIE_MATCH;

  constructor(private utilsService: UtilsService, private router: Router) { }

  ngAfterViewInit(): void {
    if (this.win || this.tie) {
      const canvasSettings = {
        target: 'canvas',
        max: 150,
        props: ['square', 'triangle', 'line', 'circle'],
        colors: [
          [165, 104, 246],
          [230, 61, 135],
          [0, 199, 228],
          [253, 214, 126],
        ],
        rotate: true,
      };
      const canvas = new ConfettiGenerator(canvasSettings);
      canvas.render();
    }
  }

  redirectToMatch() {
    this.reload.emit(true);
    this.muteSound.emit(true);
    this.redirect.emit('/match/friendly');
  }

  backTo() {
    this.reload.emit(true);
    this.muteSound.emit(true);
    
    if (this.isTournament) {
      if (this.typeOfTournament === 'season') {
        this.redirect.emit('/match/season');
      } else {
        this.redirect.emit(`/match/friendly-tournament/tournament/general/${this.tounamentId}`);
      }
    } else {
      if(this.typeOfTournament === 'friendly') this.redirect.emit('/match/friendly') 
      else this.redirect.emit('/home');
    }
  }

  mute() {
    this.isMute = !this.isMute;
    this.muteSound.emit(this.isMute);
  }

  isCordovaAvailable() {
    return (window as any).cordova !== undefined;
  }

  async shareResult() {
    const screenshot = await this.takeScreenshot();
    const modal = await this.utilsService.showModalSheet(
      SocialSharingModalComponent,
      { screenshot },
      0.8
    );
    modal.onDidDismiss().then(async (modalData: any) => {
      console.log('modal cerrado');
    });
  }

  takeScreenshot(): Promise<string> {
    return new Promise((resolve, reject) => {
      html2canvas(document.body).then(canvas => {
        resolve(canvas.toDataURL('image/png'));
      }).catch(err => {
        reject(err);
      });
    });
  }

  redirectToRoulette() {
    if(!this.tie) {
      this.reload.emit(true);
      this.muteSound.emit(true);
      this.router.navigate(['/match/battle/roulette'], {
        state: {
          isTie: this.tie,
          matchId: this.matchId,
        },
      });
    } else {
      this.router.navigate(['/match']);
    }
  }
}
