<div class="ruleta-container">
  <div *ngIf="isWinner">
    <h1>¡QUÉ LÁSTIMA!</h1>
    <div>
      <p>
        No ganaste premios en este partido
      </p>
    </div>
  </div>

  <div *ngIf="!isWinner">
    <h1>¡QUÉ SUERTE!</h1>
    <div>
      <p>
        Conservas todos los jugadores de tu plantel
      </p>
    </div>
  </div>
</div>