import { Injectable, NgZone, WritableSignal, signal } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable, tap } from 'rxjs';
import { PasswordValidator } from '../pages/auth/utils/password.validator';
import { GlobalService } from './global.service';
import { ICreateUser } from '../interfaces/ICreateUser';
import { IUpdateProfile } from '../interfaces/IUpdateProfile';
import { IPasswordRecovery } from '../interfaces/IPasswordRecovery';
import { IUser } from '../interfaces/IUser';
import { ILogin } from '../interfaces/Ilogin';
import { AppConstants } from '../app.constants';
import { MatchOrigin } from '../enums/match-origin';
import to from 'await-to-js';
import { SecurePasswordValidator } from '../utils/securePassword.validator';
import { ICard } from '../interfaces/ICard';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';
import { IuserDetail } from '../interfaces/IUserDetail';
import { CoinsComponent } from '../components/coins/coins.component';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  // a valid password must contain one upercase, one lowercase and one number
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PASSWORD_POLICY_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
  urlApi: string;
  authGoogleUrl = environment.authGoogleUrl;
  authGoogleClientId = environment.authGoogleClientId;
  authGoogleScope = environment.authGoogleScope;
  redirectUri = environment.googleRedirectUri;

  errorMessages = AppConstants.errorMessages;

  signUpForm = this.formBuilder.group(
    {
      name: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3), Validators.pattern(/^[a-zA-ZÀ-ÿ\s]+$/)]],
      lastname: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3), Validators.pattern(/^[a-zA-ZÀ-ÿ\s]+$/)]],
      province: ['', []],
      locality: ['', []],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      referredby: [
        '',
        [Validators.maxLength(12), Validators.pattern('^[A-Za-z0-9]+$')],
      ],
      referredCode: [
        '',
        [Validators.maxLength(12), Validators.pattern('^[A-Za-z0-9]+$')],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          SecurePasswordValidator.strong,
          Validators.pattern(/.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*/),
        ],
      ],
      password_confirm: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          SecurePasswordValidator.strong,
        ],
      ],
    },
    { validators: PasswordValidator },
  );

  additionalDataForm = this.formBuilder.group({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    club_users: [[], [Validators.required, Validators.maxLength(100)]],
  });

  profileForm = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(15)]],
    lastname: ['', [Validators.required, Validators.maxLength(15)]],
    referredcode: [
      '',
      [
        Validators.minLength(3),
        Validators.maxLength(12),
        Validators.pattern('^[A-Za-z0-9]+$'),
      ],
    ],
    // province: [''],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    locality_id: [''],
    email: ['', [Validators.required]],
    birthday: [''],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    club_users: [''],
    areacode: [''],
    phonenumber: [''],
    gender: [''],
  });

  loginForm = this.formBuilder.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  passwordRecoveryForm = this.formBuilder.group(
    {
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')]],
      verificationCode: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
      password: ['', [Validators.required]],
      password_confirm: ['', [Validators.required]],
    },
    { validators: PasswordValidator },
  );

  // private coinsSource = new BehaviorSubject<number>(0);
  coins$: WritableSignal<number> = signal(0);
  coinsComponent: CoinsComponent | null = null;

  constructor(
    private globalService: GlobalService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private zone: NgZone,
  ) {
    this.urlApi = this.globalService.URL_API;
  }

  setcoinsComponent(component: CoinsComponent) {
    this.coinsComponent = component;
  }


  getCompleteUserById(id) {
    return this.httpClient.get<any>(`${this.urlApi}/users/${id}`);
  }

  getCompleteUserByAccessToken(accessToken) {
    const headers = new HttpHeaders().set(
      AppConstants.headers.accessToken,
      accessToken,
    );
    return this.httpClient.get<any>(`${this.urlApi}/users/current`, {
      headers,
    });
  }

  tokenSave(tokens) {
    const savedLogin = JSON.parse(localStorage.getItem('ksport'));
    savedLogin.tokens = tokens;
    localStorage.setItem('ksport', JSON.stringify(savedLogin));
  }

  getToken() {
    const aux = JSON.parse(localStorage.getItem('ksport'));
    return aux?.tokens;
  }

  loginSave(user, tokens) {
    const loginToSave = {
      tokens,
      user,
    };
    localStorage.setItem('ksport', JSON.stringify(loginToSave));
  }

  async updateUserInfo(user) {
    const current = JSON.parse(localStorage.getItem('ksport'));
    current.user = user;
    localStorage.setItem('ksport', JSON.stringify(current));
  }

  logout() {
    const tokens = this.getToken();
    const body = { refreshToken: tokens.refreshToken };

    localStorage.removeItem('ksport');
    localStorage.removeItem('_capuid');

    return this.httpClient.post<any>(
      `${this.urlApi}/session/logout`,
      JSON.stringify(body),
    );
  }

  getLoggedUser() {
    const aux = JSON.parse(localStorage.getItem('ksport'));
    if (aux) {
      return aux.user;
    }
    return;
  }

  updatLoggedUser(user: IUser) {
    const loggedUser = JSON.parse(localStorage.getItem('ksport'));
    loggedUser.user = user;
    localStorage.setItem('ksport', JSON.stringify(loggedUser));
  }

  updateUserDetails(detail: IuserDetail) {
    const loggedUser = JSON.parse(localStorage.getItem('ksport'));
    loggedUser.user.user_detail = detail;
    localStorage.setItem('ksport', JSON.stringify(loggedUser));
    this.updateCoins(detail.coins);
  }

  /**
   * Actualiza el valor de las monedas en el servicio. Asegura que la actualización
   * ocurra dentro de la zona Angular para garantizar un correcto funcionamiento
   * de la detección de cambios. Notifica a los componentes suscritos al observable
   * `coins$` sobre el cambio, permitiendo la actualización en tiempo real de las vistas.
   */
  updateCoins(coins: number) {
    this.coinsComponent.updateCoins();
  }

  async refreshUserInfo() {
    const user = this.getCurrentUser();
    const [error, response] = await to(
      lastValueFrom(this.getCompleteUserById(user.id)),
      );
    if (response) {
      this.updateUserInfo(response.data);
    }
  }

  getSignUpForm() {
    return this.signUpForm;
  }

  getAdditionalDataForm() {
    return this.additionalDataForm;
  }

  getProfileForm() {
    return this.profileForm;
  }

  getLoginForm() {
    return this.loginForm;
  }

  getPasswordRecoveryForm() {
    return this.passwordRecoveryForm;
  }

  authentication(userData: ILogin): Observable<any> {
    return this.httpClient.post<any>(
      `${this.urlApi}/session/login`,
      JSON.stringify(userData),
    );
  }

  singUp(userData: ICreateUser): Observable<any> {
    return this.httpClient.post<any>(
      `${this.urlApi}/session/signup`,
      JSON.stringify(userData),
    );
  }

  confirmEmail(username: string, code: string, email: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.urlApi}/session/confirm-email`,
      JSON.stringify({ username, code, email }),
    );
  }

  getUsers(): Observable<any> {
    return this.httpClient.get<any>(`${this.urlApi}/users`);
  }

  updateProfile(data: IUpdateProfile): Observable<any> {
    // TODO enviar token en luagr de id
    const currentUser = JSON.parse(localStorage.getItem('ksport'));
    data.id = currentUser.user.id;
    return this.httpClient.post<any>(
      `${this.urlApi}/session/complete-profile`,
      JSON.stringify(data),
    );
  }

  forgotPassword(userEmail: { email: string }): Observable<any> {
    return this.httpClient.post<any>(
      `${this.urlApi}/session/forgot-password`,
      JSON.stringify(userEmail),
    );
  }

  confirmPassword(userData: IPasswordRecovery): Observable<any> {
    return this.httpClient.post<any>(
      `${this.urlApi}/session/confirm-password`,
      JSON.stringify(userData),
    );
  }

  getLocalities(provinceId?: number) {
    return this.httpClient.get<any>(`${this.urlApi}/localities`);
  }

  getCurrentUser() {
    const ksport = JSON.parse(localStorage.getItem('ksport'));
    if (ksport) {
      return ksport.user;
    } else {
      this.utilsService.dismissLoading();
      this.utilsService.goPage('sign-in');
    }
  }

  passwordsMatch(form: 'singup' | 'recovery') {
    switch (form) {
      case 'singup':
        return (
          this.signUpForm.get('password_confirm').value ===
          this.signUpForm.get('password').value
        );
      case 'recovery':
        return (
          this.passwordRecoveryForm.get('password_confirm').value ===
          this.passwordRecoveryForm.get('password').value
        );
      default:
        break;
    }
  }

  getUserFromMatchToken(token: string, origin: MatchOrigin) {
    return this.httpClient.get<any>(
      `${this.urlApi}/pairing/details/${origin}/${token}`,
    );
  }

  sellExcess(cards: ICard[]) {
    return this.httpClient.put<any>(
      `${this.urlApi}/delete-card`,
      JSON.stringify(cards),
    );
  }

  validateEmail(userData: any) {
    return this.httpClient.post<any>(
      `${this.urlApi}/validate-email`,
      JSON.stringify(userData),
    );
  }

  payReward(data: { id: number; coins: number; firstReward?: boolean }) {
    return this.httpClient.post<any>(
      `${this.urlApi}/pay-reward`,
      JSON.stringify(data),
    );
  }

  redirectToGoogle() {
    const redirectUriEncoded = encodeURIComponent(this.redirectUri);
    window.location.href = `${this.authGoogleUrl}/oauth2/authorize?response_type=code&client_id=${this.authGoogleClientId}&scope=${this.authGoogleScope}&redirect_uri=${redirectUriEncoded}`;
  }

  async singInWithGoogle(code: string) {
    return this.httpClient.post(`${this.urlApi}/session/sign-in/google`, {
      code
    }).pipe(
      tap((response: any) => {
        if (response.statusCode === 200 && response?.data?.user && response?.data?.tokens) {
          return true;
        }
      })
    )
  }

  updateUserTutorialStatus(status: boolean) {
    const user = this.getCurrentUser();
    return this.httpClient
      .put(`${this.urlApi}/users/${user.id}/tutorial`, {
        status,
      })
      .pipe(
        tap((res: any) => {
          if (res.status === 200) {
            this.updateUserInfo({ ...user, tutorial: res.data.tutorial });
          }
        }),
      );
  }

  async refreshToken(refreshToken) {
    this.httpClient.post(`${this.urlApi}session/refresh-token`, {
      refreshToken,
    });
  }
}
